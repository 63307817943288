import React, { useEffect, useState, useContext } from 'react';
import { useParams, Link } from 'react-router-dom';
import usePictures from '../../../hooks/usePictures';
import useLowerCase from '../../../hooks/useLowerCase';
import { getAvatar, getTransactionDetails } from '../../../api';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import transformNumbers from '../../../utils/transformNumbers';
import { selectAccount } from '../../../redux/features/user/userSlice';
import copyToclipboard from '../../../utils/copyToclipboard';
import { Button } from '@mui/material';
import { ReactComponent as IconArrowBack } from '../../../assets/icons/icon-arrow-back.svg';
import iconEyeHidden from '../../../assets/icons/icon-textfield-eye.svg';
import iconEye from '../../../assets/icons/icon-textfield-eye-hidden.svg';
import UserContext from '../../../UserContext/UserContext';
import Popup from '../../../components/Popup';
import { selectFirebaseJWT } from '../../../redux/features/user/userSlice';
import { toast } from 'react-toastify';
import ReactPlayer from 'react-player';
import rewardsbanner from '../../../assets/anims/videos/popups/rewards_ff.mp4';
import Avatar from '../../../components/Avatar';
import transformString from '../../../utils/transformString';
import ShortenedAddress from '../../../components/ShortenedAddress';

const AgentTransactionDetail = () => {
  const { loading, setLoading } = useContext(UserContext);
  const [localLoading, setLocalLoading] = useState(false);
  const { txId } = useParams();
  const [tx, setTx] = useState(null);
  const userUid = useSelector(selectAccount).uid;
  const [error, setError] = useState(false);
  const [popup, setPopup] = useState(false);
  const [registration, setRegistration] = useState( false);
  const [visible, setVisible] = useState(false);
  const toggleVisibleVerification = () => setVisible(!visible);

  const icons = usePictures(
    require.context('../../../assets/icons', false, /\.(png|jpe?g|svg|webp)$/)
  );

  const imgs = usePictures(
    require.context('../../../assets/img/p-dashboard', false, /\.(png|jpe?g|svg|webp)$/)
  );

  const { userToken } = useSelector((state) => ({
    userToken: selectFirebaseJWT(state),
  }));


  const togglePopup = () => {
    setPopup(!popup);
  };

  const setRegistrationState = (reg) => {
    setRegistration(reg);
  };

  const handleClick = (reg) => {
    setRegistrationState(reg);
    togglePopup();
  };
  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const transactionData = await getTransactionDetails(userToken, txId);
        if (transactionData?.data && transactionData.data.length > 0) {
          setTx(transactionData.data[0]);
          setLocalLoading(true);
        } else {
          throw new Error(`Something went wrong...${error}`);
        }
      } catch (error) {
        console.error('Failed to fetch transaction data.', error);
        setError(true);
        toast.error(`Something went wrong...${error?.message || 'Oops!'}`);
      } finally {
        setLoading(false);
        setLocalLoading(false);
      }
    })();
  }, [txId]);

  const txPaymentSystem = tx &&  tx?.paymentSystem !== undefined ? useLowerCase(tx?.paymentSystem) : '';
  const txOperation = tx?.operation !== undefined ? useLowerCase(tx?.operation) : '';

  const showRepeatBtnRule =
    tx?.sender?.uid === userUid && (txOperation === 'top up' || txOperation === 'withdrawal');

  return (
    <>
      {!loading && !error && tx && (
        <div className='tx-detail tx-detail-agent'>
          <Link className='c-arrow-back' to='/agent/activity'>
            <span className='c-arrow-back-icon'>
              <IconArrowBack />
            </span>
            Activity
          </Link>

          <div className='tx-detail-top'>
            <h6 className='med'> Transaction #{txId}</h6>
            <Button className='el-button' variant='contained' onClick={() => copyToclipboard(txId)}>
              <span className='el-button-icon'>
                <svg
                  width='100%'
                  height='100%'
                  viewBox='0 0 16 16'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <g id='icons/copy'>
                    <path
                      id='Vector'
                      d='M10 13.333H3.33333V4.66634C3.33333 4.29967 3.03333 3.99967 2.66667 3.99967C2.3 3.99967 2 4.29967 2 4.66634V13.333C2 14.0663 2.6 14.6663 3.33333 14.6663H10C10.3667 14.6663 10.6667 14.3663 10.6667 13.9997C10.6667 13.633 10.3667 13.333 10 13.333ZM13.3333 10.6663V2.66634C13.3333 1.93301 12.7333 1.33301 12 1.33301H6C5.26667 1.33301 4.66667 1.93301 4.66667 2.66634V10.6663C4.66667 11.3997 5.26667 11.9997 6 11.9997H12C12.7333 11.9997 13.3333 11.3997 13.3333 10.6663ZM12 10.6663H6V2.66634H12V10.6663Z'
                      fill='#6D757D'
                    />
                  </g>
                </svg>
              </span>
              <span>Copy number</span>
            </Button>
          </div>

          <div className='tx-info'>
            <div className='item'>
              <div className='item-wrap'>
                <div className='item-info'>
                  <div className='item-info-avatar'>
                     <>{txOperation === 'crypto deposit' ? (
                        <>
                          <img src={imgs['avatar-cripto@2x.png']} alt='' />
                        </>
                      ) : txOperation === 'remit' ? (
                        <img src={imgs['avatar-pesa-agent@2x.png']} alt='' />
                      ) : txOperation === 'mpesa' || txOperation === 'withdrawal' ? (
                        <img src={imgs['avatar-mpesa-agent@2x.png']} alt='' />
                      ) : txOperation === 'top up' ? (
                        <Avatar data={tx?.sender?.uid === userUid ? tx?.receiver : tx?.sender} />
                      ) : txOperation === 'referral reward' || txOperation === 'signup reward' ? (
                        <>
                          <img src={imgs['avatar-pesa-ref@2x.png']} alt='' />
                        </>
                      ) : (
                        <Avatar data={tx?.sender?.uid !== userUid ? tx?.sender : tx?.receiver} />
                      )}
                    </>
                  </div>
                  <div className='item-info-desc'>
                   {txOperation === 'crypto deposit' ? (
                    <div className='txagent-item-name'>Crypto deposit</div>
                    ) : txPaymentSystem === 'mpesa' && tx?.sender?.phoneNumber ? (
                      <div className='item-phone-num'>{tx?.sender?.phoneNumber}</div>
                    ) : txOperation === 'signup reward' ? (
                      <div className='item-rew'>{tx?.sender?.name}</div>
                    ) : tx?.agentSender || tx?.agentReceiver ? (
                      <>
                        {(tx?.sender?.phoneNumber || tx?.receiver?.phoneNumber) && (
                          <div className='item-phone'>
                            {tx?.sender?.phoneNumber && (
                              <div className='item-phone-num'>
                                {tx?.sender?.phoneNumber}
                              </div>
                            )}
                            <img src={icons['icon-arrow-trans.svg']} alt='' />
                            {tx?.receiver?.phoneNumber && (
                              <div className='item-phone-num'>
                                {tx?.receiver?.phoneNumber}
                              </div>
                            )}
                          </div>
                        )}
                      </>
                    ) : (tx?.sender?.uid === userUid && tx?.receiver?.name & transformString(tx?.receiver?.name) !== ' ')? (
                      <>
                        <div className='item-name'>{tx?.receiver?.name}</div>
                      </>
                    ) : (tx?.receiver?.uid === userUid && tx?.sender?.name && transformString(tx?.sender?.name) !== ' ') ? (
                      <>
                        <div className='item-name'>{tx?.sender?.name}</div>
                      </>
                    ) : (
                      <></>
                    )}
                    <div className='item-info-bottom'>
                      {tx?.status === 'referral reward' ? (
                        <div className='item-info-ag'>
                          {tx?.agentSender.name}. has registered using your referral link
                        </div>
                      ) : tx?.status === 'cancel' ? (
                        <div className='item-info-ag'>
                          <div className='avatar'>
                            <img src={icons['icon-dec-cancel.svg']} alt='' />
                          </div>
                          <div className='text'>Canceled by sender</div>
                        </div>
                      ) : txOperation === 'remit' ? (
                        <div className='item-info-ag'>
                          <Avatar
                            data={
                              userUid === tx?.agentSender?.uid ? tx?.agentReceiver : tx?.agentSender
                            }
                          />
                        </div>
                      ) : (
                        <></>
                      )}
                      {tx?.timestamp && (
                        <div className='item-info-date'>
                          {dayjs(parseInt(tx?.timestamp)).format('DD MMM, HH:mm')}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className='item-right'>
                  <div className='item-quantity'>
                    <div
                      className={`item-quantity-wrap ${
                        txOperation === 'crypto deposit'
                         ? 'wallet-quan'
                        : (tx?.agentSender?.uid === userUid ||
                        userUid === tx?.sender?.uid ||
                        tx?.type === 'outgoing')
                          ? 'sent-quan'
                          : 'received-quan'
                      }`}
                    >
                      <div className='icon'>
                        {txOperation === 'crypto deposit' ? (
                          <img src={icons['icon-wallet.svg']} alt='' />
                        ) : (tx?.agentSender?.uid === userUid ||
                        userUid === tx?.sender?.uid ||
                        tx?.type === 'outgoing') ? (
                          <img src={icons['icon-sent.svg']} alt='' />
                        ) : (
                          <img src={icons['icon-received.svg']} alt='' />
                        )}
                      </div>

                      <div className='text'>
                        {transformNumbers(Number(tx?.amount), 2)} {tx?.currency}
                      </div>
                    </div>
                  </div>
                  <div className='item-status'>
                    {tx?.status === 'completed' ? (
                      <div className='item-status-wrap success'>
                        <div className='icon'>
                          <img src={icons['icon-success.svg']} alt='' />
                        </div>
                      </div>
                    ) : tx?.status === 'failed' || tx?.status === 'cancelled' ? (
                      <div className='item-status-wrap failed'>
                        <div className='icon'>
                          <img src={icons['icon-failed.svg']} alt='' />
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='tx-desc'>
            <div className='tx-desc-list'>
              {txOperation !== 'referral reward' ? (
                <>
                  {/* txId for all transaction */}
                  {(tx?.txId || tx?.paymentSystem || tx?.timestamp) && (
                    <div className='tx-desc-wrap'>
                      {tx?.txId && (
                        <div className='tx-desc-item'>
                          <div className='el-text-m tx-desc-label'>Transaction ID</div>
                          <div className='el-text-m tx-desc-text'>{tx?.txId}</div>
                        </div>
                      )}
                      {/* paymentSystem operation transfer  */}
                      {(tx?.paymentSystem && txOperation !== 'crypto deposit') && (
                        <div className='tx-desc-item'>
                          <div className='el-text-m tx-desc-label'>Payment system</div>
                          <div className='el-text-m tx-desc-text name'>
                            {tx?.paymentSystem.toLowerCase()}
                          </div>
                        </div>
                      )}
                      {/* date for all transaction */}
                      {tx?.timestamp && (
                        <div className='tx-desc-item'>
                          <div className='el-text-m tx-desc-label'>Initiation date</div>
                          <div className='el-text-m tx-desc-text'>
                            {dayjs(parseInt(tx?.timestamp)).format('MM/DD/YYYY HH:mm:ss')}
                          </div>
                        </div>
                      )}
                      {/* address for crypto deposit  */}
                      {txOperation === 'crypto deposit' ? (
                          <>
                            {tx?.sender?.address && (
                              <div className='tx-desc-item'>
                                <div className='el-text-m tx-desc-label'>
                                  <>Sender wallet address</>
                                </div>
                                <div className='el-text-m tx-desc-text name'>
                                  <span
                                    className='address'
                                    onClick={() => copyToclipboard(tx?.sender?.address)}
                                  >
                                    <ShortenedAddress address={tx?.sender?.address} />
                                    <img className='address-copy' src={icons['icon-copy.svg']} alt='' />
                                  </span>
                                </div>
                              </div>
                            )}
                          </>
                        ) : (
                          <></>
                        )}
                      {tx?.receiver?.uid === userUid ? (
                        <>
                          {tx?.sender?.name && (
                            <div className='tx-desc-item'>
                              <div className='el-text-m tx-desc-label'>Sender</div>
                              <div className='el-text-m tx-desc-text'>{tx?.sender?.name}</div>
                            </div>
                          )}
                        </>
                      ) : tx?.sender?.uid === userUid ? (
                        <>
                          {tx?.receiver?.name && (
                            <div className='tx-desc-item'>
                              <div className='el-text-m tx-desc-label'>Receiver</div>
                              <div className='el-text-m tx-desc-text'>{tx?.receiver?.name}</div>
                            </div>
                          )}
                        </>
                      ) : (txOperation === 'withdrawal'|| txOperation === 'm-pesa' || txOperation === 'mpesa-agent') ? (
                        <>
                        {txPaymentSystem === 'm-pesa' ? (
                              <>
                              <div className='tx-desc-item'>
                              <div className='el-text-m tx-desc-label'>Receiver</div>
                              <div className='el-text-m tx-desc-text'>{(tx?.receiver?.name && transformString(tx?.receiver?.name) !== ' ') ? tx?.receiver.name : tx?.receiver?.phoneNumber}</div>
                            </div>
                              </>
                          ):(
                          <></>
                          )}
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  )}
                </>
              ) : (
                <>
                  {/* referral reward */}
                  {tx?.txId && (
                    <div className='tx-desc-wrap'>
                      {tx?.txId && (
                        <div className='tx-desc-item'>
                          <div className='el-text-m tx-desc-label'>Transaction ID</div>
                          <div className='el-text-m tx-desc-text'>{tx?.txId}</div>
                        </div>
                      )}
                    </div>
                  )}
                  {(tx?.timestamp || tx?.comment || tx?.amount) && (
                    <div className='tx-desc-wrap'>
                      {tx?.amount && (
                        <div className='tx-desc-item'>
                          <div className='el-text-m tx-desc-label'>Transaction amount</div>
                          <div className='el-text-m semi tx-desc-text'>
                            {tx?.amount} {tx?.currency}
                          </div>
                        </div>
                      )}
                      {/* date for all transaction */}

                      {tx?.timestamp && (
                        <div className='tx-desc-item'>
                          <div className='el-text-m tx-desc-label'>Initiation date</div>
                          <div className='el-text-m tx-desc-text'>
                            {dayjs(parseInt(tx?.timestamp)).format('MM/DD/YYYY HH:mm:ss')}
                          </div>
                        </div>
                      )}
                      {tx?.comment && tx?.comment.length > 0 && (
                        <div className='tx-desc-item'>
                          <div className='el-text-m tx-desc-label'>Comment</div>
                          <div className='el-text-m tx-desc-text'>{tx?.comment}</div>
                        </div>
                      )}
                    </div>
                  )}
                </>
              )}
              {/* Agent sender */}
              {userUid === tx?.agentSender?.uid || tx?.type === 'outgoing' ? (
                <>
                  {/* amount */}
                  {(tx?.amount ||
                    tx?.fee ||
                    tx?.totalAmount ||
                    tx?.comment ||
                    tx?.verification) && (
                    <div className='tx-desc-wrap'>
                      {tx?.amount && (
                        <div className='tx-desc-item'>
                          <div className='el-text-m tx-desc-label'>Transaction amount</div>
                          <div className='el-text-l semi tx-desc-text'>
                          {transformNumbers(Number(tx?.amount), 3)} {tx?.currency}
                          </div>
                        </div>
                      )}
                      {/* fee */}
                      {tx?.fee && (
                        <div className='tx-desc-item'>
                          <div className='el-text-m tx-desc-label'>Transaction fee</div>
                          <div className='el-text-m tx-desc-text'>
                          {transformNumbers(Number(tx?.fee), 4)} {tx?.feeCurrency}
                          </div>
                        </div>
                      )}
                      {/* total for all transaction */}
                      {tx?.totalAmount && (
                        <div className='tx-desc-item'>
                          <div className='el-text-m tx-desc-label'>Total</div>
                          <div className='el-text-l semi tx-desc-text'>
                          {transformNumbers(Number(tx?.totalAmount), 4)} {tx?.currency}
                          </div>
                        </div>
                      )}
                      {tx?.comment && tx?.comment.length > 0 && (
                        <div className='tx-desc-item'>
                          <div className='el-text-m tx-desc-label'>Comment</div>
                          <div className='el-text-m tx-desc-text'>{tx?.comment}</div>
                        </div>
                      )}
                      {tx?.verification && (
                        <div className='tx-desc-item'>
                          <div className='el-text-m tx-desc-label'>Verification</div>
                          <div className='el-text-m tx-desc-text verification'>
                            {' '}
                            {visible ? tx?.verification : '****'}
                            <span className='verification-icon' onClick={toggleVisibleVerification}>
                              <img
                                src={visible ? iconEye : iconEyeHidden}
                                alt='Toggle visibility'
                              />
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                  {/* sender */}
                  {(tx?.sender?.name || tx?.sender?.phoneNumber || tx?.sender?.location) && (
                    <div className='tx-desc-wrap'>
                      {tx?.sender?.name && (
                        <div className='tx-desc-item'>
                          <div className='el-text-m tx-desc-label'>Sender</div>
                          <div className='el-text-m tx-desc-text'>{tx?.sender?.name}</div>
                        </div>
                      )}

                      {tx?.sender?.phoneNumber && (
                        <div className='tx-desc-item'>
                          <div className='el-text-m tx-desc-label'>Sender’s phone</div>
                          <div className='el-text-m tx-desc-text name'>
                            {tx?.sender?.phoneNumber}
                          </div>
                        </div>
                      )}

                      {tx?.sender?.location && (
                        <div className='tx-desc-item'>
                          <div className='el-text-m tx-desc-label'>Sender’s location</div>
                          <div className='el-text-m tx-desc-text'>
                            {tx?.sender?.location?.country}, {tx?.sender?.location?.city}
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                  {(tx?.agentReceiver?.name ||
                    tx?.receiver?.name ||
                    tx?.receiver?.phoneNumber ||
                    tx?.receiver?.location) && (
                    <div className='tx-desc-wrap'>
                      {tx?.pickupLocation && (
                        <div className='tx-desc-item'>
                          <div className='el-text-m tx-desc-label'>
                            Receiver’s cash pickup location
                          </div>
                          <div className='el-text-m tx-desc-text'>
                            {tx?.pickupLocation?.country}, {tx?.pickupLocation?.suburb}
                          </div>
                        </div>
                      )}
                      {tx?.agentReceiver?.name && (
                        <div className='tx-desc-item'>
                          <div className='el-text-m tx-desc-label'>Receiving Agent</div>
                          <div className='el-text-m tx-desc-text'>{tx?.agentReceiver?.name}</div>
                        </div>
                      )}
                      {tx?.receiver?.name && (
                        <div className='tx-desc-item'>
                          <div className='el-text-m tx-desc-label'>Receiver</div>
                          <div className='el-text-m tx-desc-text'>{tx?.receiver?.name}</div>
                        </div>
                      )}
                      {tx?.receiver?.phoneNumber && (
                        <div className='tx-desc-item'>
                          <div className='el-text-m tx-desc-label'>Receiver’s phone</div>
                          <div className='el-text-m tx-desc-text name'>
                            {tx?.receiver?.phoneNumber}
                          </div>
                        </div>
                      )}
                      {tx?.receiver?.location && (
                        <div className='tx-desc-item'>
                          <div className='el-text-m tx-desc-label'>Receiver’s location</div>
                          <div className='el-text-m tx-desc-text'>
                            {tx?.receiver?.location?.country}, {tx?.receiver?.location?.city}
                          </div>
                        </div>
                      )}
                      {/* Date of birth */}
                      {tx?.receiver?.DateOfBirth && (
                        <div className='tx-desc-item'>
                          <div className='el-text-m tx-desc-label'>Date of birth</div>
                          <div className='el-text-m tx-desc-text'>{tx?.receiver?.dateOfBirth}</div>
                        </div>
                      )}
                      {/* Gender */}
                      {tx?.receiver?.gender && (
                        <div className='tx-desc-item'>
                          <div className='el-text-m tx-desc-label'>Gender</div>
                          <div className='el-text-m tx-desc-text'>{tx?.receiver?.gender}</div>
                        </div>
                      )}
                    </div>
                  )}
                  {tx?.status === 'cancelled' && (
                    <div className='tx-desc-wrap'>
                      {tx?.cancelReason && (
                        <div className='tx-desc-item'>
                          <div className='el-text-m tx-desc-label'>Reason to cancel</div>
                          <div className='el-text-m tx-desc-text'>{tx?.cancelReason}</div>
                        </div>
                      )}
                    </div>
                  )}
                </>
              ) : userUid === tx?.agentReceiver?.uid || tx?.type === 'incoming' ? (
                <>
                  {/* Agent receiver */}
                  {/* amount */}
                  {(tx?.amount || tx?.comment || tx?.verification) && (
                    <div className='tx-desc-wrap'>
                      {tx?.amount && (
                        <div className='tx-desc-item'>
                          <div className='el-text-m tx-desc-label'>Transaction amount</div>
                          <div className='el-text-m tx-desc-text'>
                            {tx?.amount} {tx?.currency}
                          </div>
                        </div>
                      )}
                      {tx?.comment && tx?.comment.length > 0 && (
                        <div className='tx-desc-item'>
                          <div className='el-text-m tx-desc-label'>Comment</div>
                          <div className='el-text-m tx-desc-text'>{tx?.comment}</div>
                        </div>
                      )}
                      {tx?.verification && (
                        <div className='tx-desc-item'>
                          <div className='el-text-m tx-desc-label'>Verification</div>
                          <div className='el-text-m tx-desc-text verification'>
                            {' '}
                            {visible ? tx?.verification : '****'}
                            <span className='verification-icon' onClick={toggleVisibleVerification}>
                              <img
                                src={visible ? iconEye : iconEyeHidden}
                                alt='Toggle visibility'
                              />
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                  {/* sender */}
                  {(tx?.agentSender?.name ||
                    tx?.sender?.name ||
                    tx?.sender?.phoneNumber ||
                    tx?.sender?.location) && (
                    <div className='tx-desc-wrap'>
                      {tx?.agentSender?.name && (
                        <div className='tx-desc-item'>
                          <div className='el-text-m tx-desc-label'>Sending Agent</div>
                          <div className='el-text-m tx-desc-text'>{tx?.agentSender?.name}</div>
                        </div>
                      )}
                      {tx?.sender?.name && (
                        <div className='tx-desc-item'>
                          <div className='el-text-m tx-desc-label'>Sender</div>
                          <div className='el-text-m tx-desc-text'>{tx?.sender?.name}</div>
                        </div>
                      )}

                      {tx?.sender?.phoneNumber && (
                        <div className='tx-desc-item'>
                          <div className='el-text-m tx-desc-label'>Sender’s phone</div>
                          <div className='el-text-m tx-desc-text name'>
                            {tx?.sender?.phoneNumber}
                          </div>
                        </div>
                      )}

                      {tx?.sender?.location && (
                        <div className='tx-desc-item'>
                          <div className='el-text-m tx-desc-label'>Sender’s location</div>
                          <div className='el-text-m tx-desc-text'>
                            {tx?.sender?.location?.country}, {tx?.sender?.location?.city}
                          </div>
                        </div>
                      )}
                    </div>
                  )}

                  {(tx?.receiver?.name || tx?.receiver?.phoneNumber || tx?.receiver?.location) && (
                    <div className='tx-desc-wrap'>
                      {/* Receiver’s cash pickup location */}
                      {tx?.pickupLocation && (
                        <div className='tx-desc-item'>
                          <div className='el-text-m tx-desc-label'>
                            Receiver’s cash pickup location
                          </div>
                          <div className='el-text-m tx-desc-text'>
                            {tx?.pickupLocation?.country}, {tx?.pickupLocation?.suburb}
                          </div>
                        </div>
                      )}
                      {tx?.receiver?.name && (
                        <div className='tx-desc-item'>
                          <div className='el-text-m tx-desc-label'>Receiver</div>
                          <div className='el-text-m tx-desc-text'>{tx?.receiver?.name}</div>
                        </div>
                      )}
                      {tx?.receiver?.phoneNumber && (
                        <div className='tx-desc-item'>
                          <div className='el-text-m tx-desc-label'>Receiver’s phone</div>
                          <div className='el-text-m tx-desc-text name'>
                            {tx?.receiver?.phoneNumber}
                          </div>
                        </div>
                      )}
                      {tx?.receiver?.location && (
                        <div className='tx-desc-item'>
                          <div className='el-text-m tx-desc-label'>Receiver’s location</div>
                          <div className='el-text-m tx-desc-text'>
                            {tx?.receiver?.location?.country}, {tx?.receiver?.location?.city}
                          </div>
                        </div>
                      )}
                      {/* Date of birth */}
                      {tx?.receiver?.dateOfBirth && (
                        <div className='tx-desc-item'>
                          <div className='el-text-m tx-desc-label'>Date of birth</div>
                          <div className='el-text-m tx-desc-text'>{tx?.receiver?.dateOfBirth}</div>
                        </div>
                      )}
                      {/* Gender */}
                      {tx?.receiver?.gender && (
                        <div className='tx-desc-item'>
                          <div className='el-text-m tx-desc-label'>Gender</div>
                          <div className='el-text-m tx-desc-text'>{tx?.receiver?.gender}</div>
                        </div>
                      )}
                    </div>
                  )}
                </>
              ) : (
                <></>
              )}
              {!tx?.agentSender?.uid && tx?.sender?.uid === userUid ? (
                <>
                  {/* send to agent */}
                  {(tx?.amount ||
                    tx?.fee ||
                    tx?.totalAmount ||
                    tx?.comment ||
                    tx?.verification) && (
                    <div className='tx-desc-wrap'>
                      {tx?.amount && (
                        <div className='tx-desc-item'>
                          <div className='el-text-m tx-desc-label'>You sent exactly</div>
                          <div className='el-text-l semi tx-desc-text'>
                            {transformNumbers(tx?.amount, 2)} {tx?.currency}
                          </div>
                        </div>
                      )}
                      {/* fee */}
                      {tx?.fee && (
                        <div className='tx-desc-item'>
                          <div className='el-text-m tx-desc-label'>Transaction fee</div>
                          <div className='el-text-m tx-desc-text'>
                            {tx?.fee} {tx?.feeCurrency}
                          </div>
                        </div>
                      )}
                      {/* total for all transaction */}
                      {tx?.totalAmount && (
                        <div className='tx-desc-item'>
                          <div className='el-text-m tx-desc-label'>Total</div>
                          <div className='el-text-l semi tx-desc-text'>
                            {/* {transformNumbers(tx?.totalAmount, 2)}  */}
                            {tx?.totalAmount}{tx?.currency}
                          </div>
                        </div>
                      )}
                      {tx?.txAmountInUSD && (
                        <div className='tx-desc-item'>
                          <div className='el-text-m tx-desc-label'>USD Equivalent</div>
                          <div className='el-text-m tx-desc-text'>
                            {transformNumbers(tx?.txAmountInUSD, 2)} USD
                          </div>
                        </div>
                      )}
                      {tx?.comment && tx?.comment.length > 0 && (
                        <div className='tx-desc-item'>
                          <div className='el-text-m tx-desc-label'>Comment</div>
                          <div className='el-text-m tx-desc-text'>{tx?.comment}</div>
                        </div>
                      )}
                    </div>
                  )}
                </>
              ) : !tx?.agentReceiver?.uid && tx?.receiver?.uid === userUid ? (
                <>
                  {/* receive from agent */}
                  {(tx?.amount || tx?.comment) && (
                    <div className='tx-desc-wrap'>
                      {tx?.amount && (
                        <div className='tx-desc-item'>
                          { txOperation === 'crypto deposit' ? (
                          <div className='el-text-m tx-desc-label'>You deposited</div>
                           )  : (
                            <div className='el-text-m tx-desc-label'>You received</div>
                           )}
                          <div className='el-text-l semi tx-desc-text'>
                            {transformNumbers(tx?.amount, 2)} {tx?.currency}
                          </div>
                        </div>
                      )}
                      {tx?.comment && tx?.comment.length > 0 && (
                        <div className='tx-desc-item'>
                          <div className='el-text-m tx-desc-label'>Comment</div>
                          <div className='el-text-m tx-desc-text'>{tx?.comment}</div>
                        </div>
                      )}
                    </div>
                  )}
                </>
              ) : (
                <></>
              )}

              {/* send to M-Pesa */}
              {((txOperation === 'withdrawal' || txOperation === 'mpesa' || txOperation === 'mpesa-agent')&&(txPaymentSystem === 'm-pesa')) && (
                <>
                  {(tx?.amount ||
                    tx?.fee ||
                    tx?.totalAmount ||
                    tx?.comment ||
                    tx?.verification) && (
                    <div className='tx-desc-wrap'>
                      {tx?.amount && (
                        <div className='tx-desc-item'>
                          <div className='el-text-m tx-desc-label'>You sent exactly</div>
                          <div className='el-text-m tx-desc-text'>
                            {tx?.amount} {tx?.currency}
                          </div>
                        </div>
                      )}
                      {/* fee */}
                      {tx?.fee && (
                        <div className='tx-desc-item'>
                          <div className='el-text-m tx-desc-label'>Transaction fee</div>
                          <div className='el-text-m tx-desc-text'>
                            {tx?.fee} {tx?.feeCurrency}
                          </div>
                        </div>
                      )}
                      {/* total for all transaction */}
                      {tx?.totalAmount && (
                        <div className='tx-desc-item'>
                          <div className='el-text-m tx-desc-label'>Total</div>
                          <div className='el-text-m tx-desc-text'>
                            {tx?.totalAmount} {tx?.currency}
                          </div>
                        </div>
                      )}
                      {tx?.txAmountInUSD && (
                        <div className='tx-desc-item'>
                          <div className='el-text-m tx-desc-label'>USD Equivalent</div>
                          <div className='el-text-m tx-desc-text'>
                            {transformNumbers(tx?.txAmountInUSDNumber, 2)} USD
                          </div>
                        </div>
                      )}
                      {tx?.comment && tx?.comment.length > 0 && (
                        <div className='tx-desc-item'>
                          <div className='el-text-m tx-desc-label'>Comment</div>
                          <div className='el-text-m tx-desc-text'>{tx?.comment}</div>
                        </div>
                      )}
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
          {((tx?.type === 'outgoing' && tx?.status !== 'cancelled') ||
          tx?.type === 'incoming'||
          txPaymentSystem === 'mpesa' ||
            txOperation === 'mpesa' ||
            txOperation === 'mpesa-agent' ||
            txOperation === 'top up') && (
            <>
              {/* register user reward */}
              {tx?.registerUserRewards && Number(tx?.registerUserRewards) !== 0 ? (
                <>
                  <div className='tx-reward'>
                    <div className='tx-reward-wrap'>
                      <div className='tx-reward-left'>
                        <div className='tx-reward-left-img'>
                          <img src={imgs['icon-reward-no-bg.png']} alt='reward image' />
                        </div>
                        <div className='tx-reward-label'>
                          {Number(tx?.registerUserRewards).toFixed(6)} PESA
                        </div>
                      </div>
                      <div className='tx-reward-text'>
                      Your reward for a newly registered user
                        <span className='tx-reward-text-icon' onClick={() => handleClick(false)}>
                          <svg
                            width='16'
                            height='16'
                            viewBox='0 0 16 16'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M15 8C15 11.866 11.866 15 8 15C4.13401 15 1 11.866 1 8C1 4.13401 4.13401 0.999999 8 0.999999C11.866 1 15 4.13401 15 8Z'
                              stroke='#6D757D'
                              strokeWidth='2'
                            />
                            <path
                              d='M9 11C9 11.5523 8.55228 12 8 12C7.44772 12 7 11.5523 7 11C7 10.4477 7.44772 10 8 10C8.55228 10 9 10.4477 9 11Z'
                              fill='#6D757D'
                            />
                            <path
                              d='M6 6.5V6.5C6 5.67157 6.67157 5 7.5 5H8.76393C9.44659 5 10 5.55341 10 6.23607V6.23607C10 6.70426 9.73548 7.13226 9.31672 7.34164L8.44721 7.77639C8.17313 7.91343 8 8.19357 8 8.5V8.5'
                              stroke='#6D757D'
                              strokeWidth='1.75'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                          </svg>
                        </span>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}

              {/* Your reward banner */}
              {tx?.rewardInPesa && Number(tx?.rewardInPesa) !== 0 && (
                <>
                  <div className='tx-reward'>
                    <div className='tx-reward-wrap'>
                      <div className='tx-reward-left'>
                        <div className='tx-reward-left-img'>
                          <img src={imgs['icon-reward-no-bg.png']} alt='reward image' />
                        </div>
                        <div className='tx-reward-label'>
                          {Number(tx?.rewardInPesa).toFixed(6)} PESA
                        </div>
                      </div>
                      <div className='tx-reward-text'>
                        Your reward
                        <span className='tx-reward-text-icon' onClick={() => handleClick(false)}>
                          <svg
                            width='16'
                            height='16'
                            viewBox='0 0 16 16'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M15 8C15 11.866 11.866 15 8 15C4.13401 15 1 11.866 1 8C1 4.13401 4.13401 0.999999 8 0.999999C11.866 1 15 4.13401 15 8Z'
                              stroke='#6D757D'
                              strokeWidth='2'
                            />
                            <path
                              d='M9 11C9 11.5523 8.55228 12 8 12C7.44772 12 7 11.5523 7 11C7 10.4477 7.44772 10 8 10C8.55228 10 9 10.4477 9 11Z'
                              fill='#6D757D'
                            />
                            <path
                              d='M6 6.5V6.5C6 5.67157 6.67157 5 7.5 5H8.76393C9.44659 5 10 5.55341 10 6.23607V6.23607C10 6.70426 9.73548 7.13226 9.31672 7.34164L8.44721 7.77639C8.17313 7.91343 8 8.19357 8 8.5V8.5'
                              stroke='#6D757D'
                              strokeWidth='1.75'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                          </svg>
                        </span>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </>
          )}
          {showRepeatBtnRule && (
            <div className='tx-btn'>
              <Link
                variant='outlined'
                to={
                  transformString(tx?.paymentSystem) === 'pesabase'
                    ? '/agent/send/system/agent'
                    : '/agent/send/system/mpesa'
                }
                className='el-button orange'
                state={{
                  transferData: {
                    paymentSystem:
                      transformString(tx?.paymentSystem) === 'PESABASE' ? 'pesabase' : 'm-pesa',
                    userReceiver: tx?.receiver,
                    userReview: {
                      sendValue: tx?.amount || tx?.totalAmount,
                      currency: tx?.currency.toLowerCase(),
                    },
                  },
                }}
              >
                Repeat transaction
              </Link>
            </div>
          )}

          <Popup open={popup} togglePopUp={togglePopup}>
            <div className='modal-content'>
              <div className='modal-img'>
                <ReactPlayer
                  className='player'
                  controls={false}
                  playing={true}
                  loop={true}
                  muted={true}
                  playsinline={true}
                  url={rewardsbanner}
                  width='100%'
                  height='100%'
                />
              </div>

              <div className='modal-desc'>
                <div className='modal-desc-title'>
                {(tx?.registerUserRewards && Number(tx?.registerUserRewards) !== 0)
                    ? 'Register new users and receive bonus to your balance!'
                    : 'Rewards for you!'}
                  </div>

                <div className='el-text-m modal-desc-text'>
                  {(tx?.registerUserRewards && Number(tx?.registerUserRewards) !== 0) ? (
                    <>
                      {`
                      We pay you ${tx?.registerUserRewards} PESA each time you register a new user and they make 100 USD of transactions.
                      Your referral bonus will be added to your PESA balance after the transaction is processed.
                      You can also find it in your Activity history.
                      `}
                      <Link target='_blank' to={'/referral'} className='el-button-link'>
                        More details here
                      </Link>
                    </>
                  ) : (
                    <>
                      {`
                      You will receive up to ${tx?.rewardInPesa} PESA for this transaction.
                      We reward you each time you use our platform to send money.
                      Your reward will be added to your PESA balance after the transaction is processed.
                      You can find it in the transaction details in your Activity history.
                      `}
                      <Link target='_blank' to={'/rewards'} className='el-button-link'>
                        More details here
                      </Link>
                    </>
                  )}
                </div>
              </div>

              <div className='modal-btns'>
                <Button
                  className='el-button orange el-button-continue'
                  variant='contained'
                  onClick={togglePopup}
                >
                  Ok, got it
                </Button>
              </div>
            </div>
          </Popup>

        </div>
      )}

      {!loading && error && <h6 className='el-title-error'>Error</h6>}
    </>
  );
};

export default AgentTransactionDetail;
